import { FC } from 'react';
import { PictureProps } from 'src/components/atoms/Picture';
import cx from 'classnames';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { ActionButtonProps } from '../../atoms/ActionButton';
import { RelatedProductProps } from '../RelatedProduct';
import { ActionButtonVariant } from '../../atoms/ActionButton/styled';
import * as SC from './styled';
export type SoloBrandFormatsProps = Pick<MinimalSeoBlock, 'htmlTag'> & {
  id?: string;
  className?: string;
  relatedProducts: RelatedProductProps[];
  actionButtonProps?: ActionButtonProps;
  pictureProps?: PictureProps;
};
const SoloBrandFormats: FC<SoloBrandFormatsProps> = ({
  id,
  className,
  htmlTag,
  relatedProducts,
  actionButtonProps,
  pictureProps
}) => {
  return <SC.Container id={id} className={cx('SoloBrandFormats', className)} as={htmlTag}>
      {relatedProducts && relatedProducts.length > 0 && <SC.Products $withPicture={pictureProps ? true : false}>
          <Swiper modules={[Pagination]} pagination={{
        clickable: true
      }} slidesPerView={1.1} spaceBetween={20} breakpoints={{
        // when window width is >= 640px
        768: {
          slidesPerView: 2
        },
        // when window width is >= 768px
        1200: {
          slidesPerView: 3,
          spaceBetween: 30
        }
      }}>
            {relatedProducts?.map((product, i) => <SwiperSlide key={`SoloBrandFormats-${i}`}>
                <SC.Product {...product} />
              </SwiperSlide>)}
          </Swiper>
        </SC.Products>}
      {actionButtonProps && <SC.Action $withPicture={pictureProps ? true : false}>
          <SC.StyledActionButton variant={ActionButtonVariant.primary} {...actionButtonProps} />
        </SC.Action>}
      {pictureProps && <SC.StyledPicture {...pictureProps} />}
    </SC.Container>;
};
export default SoloBrandFormats;