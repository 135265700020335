import React, { FC } from 'react';
import cx from 'classnames';
import * as SC from './styled';
export type SoloBrandProps = Pick<MinimalSeoBlock, 'htmlTag'> & {
  id?: string;
  className?: string;
  children?: React.ReactNode;
};
const SoloBrand: FC<SoloBrandProps> = ({
  id,
  className,
  htmlTag,
  children
}) => {
  return children ? <SC.Container id={id} className={cx('SoloBrand', className)} as={htmlTag}>
      {children}
    </SC.Container> : null;
};
export default SoloBrand;