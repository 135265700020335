import { FC } from 'react';
import { PictureProps } from 'src/components/atoms/Picture';
import cx from 'classnames';
import Html, { HtmlProps } from '../../atoms/Html';
import { ActionButtonProps } from '../../atoms/ActionButton';
import * as SC from './styled';
export type SoloMultiBrandIntroProps = MinimalSeoBlock & {
  id?: string;
  index: number;
  className?: string;
  title?: string;
  items?: {
    subtitle?: string;
    htmlProps?: HtmlProps;
    actionButtonProps?: ActionButtonProps;
    pictureProps?: PictureProps;
    pictureRatio?: number;
    reverseOnDesktop?: boolean;
  }[];
};
const SoloMultiBrandIntro: FC<SoloMultiBrandIntroProps> = ({
  id,
  index,
  className,
  htmlTag,
  htmlTitleTag,
  title,
  items
}) => {
  return <SC.Container id={id} className={cx('SoloMultiBrandIntro', className)} as={htmlTag}>
      {title && <SC.Title as={htmlTitleTag}>
          <strong>{title}</strong>
        </SC.Title>}
      {items && items.length && items.map((item, subIndex) => <SC.Item key={subIndex} $reverseOnDesktop={item.reverseOnDesktop}>
            <SC.SubTitle as={htmlTitleTag} id={`Brand-${index}-${subIndex}`} key={`Brand-${index}-${subIndex}`}>
              {item.subtitle && <Html htmlTag={'span'}>{item.subtitle}</Html>}
            </SC.SubTitle>
            {item.pictureProps && <SC.StyledPicture {...item.pictureProps} $pictureRatio={item.pictureRatio} />}
            {item.htmlProps && <SC.Text htmlTag={'div'} {...item.htmlProps} />}
            {item.actionButtonProps && <SC.Action {...item.actionButtonProps} />}
          </SC.Item>)}
    </SC.Container>;
};
export default SoloMultiBrandIntro;