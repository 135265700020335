import { FC } from 'react';
import { PictureProps } from 'src/components/atoms/Picture';
import cx from 'classnames';
import * as SC from './styled';
export type SoloBrandPictureProps = {
  className?: string;
  pictureProps?: PictureProps;
  mobilePictureProps?: PictureProps;
  widthRoundedCorner?: boolean;
};
const SoloBrandPicture: FC<SoloBrandPictureProps> = ({
  className,
  pictureProps,
  mobilePictureProps,
  widthRoundedCorner = true
}) => {
  return pictureProps || mobilePictureProps ? <SC.Container>
      {pictureProps && <SC.DesktopPicture className={cx('SoloBrandPicture', className)} $widthRoundedCorner={widthRoundedCorner} {...pictureProps} />}
      {mobilePictureProps && <SC.MobilePicture className={cx('SoloBrandPicture', className)} $widthRoundedCorner={widthRoundedCorner} {...mobilePictureProps} />}
    </SC.Container> : null;
};
export default SoloBrandPicture;