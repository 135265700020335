import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SoloBrandBlock as Data } from '../../../graphql/generated/api-graphql';
import { BlockProps } from '../props';
import SoloBrand from '../../../components/molecules/SoloBrand';
import SoloBrandIntro from '../../../components/molecules/SoloBrandIntro';
import { ActionButtonVariant } from '../../../components/atoms/ActionButton/styled';
import { Icons } from '../../../components/atoms/Icon';
import { wediaImagesTransform } from '../../Common/wedia';
import SoloBrandPicture from '../../../components/molecules/SoloBrandPicture';
import SoloBrandFormats from '../../../components/molecules/SoloBrandFormats';
import Router, { routes } from '../../../routes/Router';
import SoloMultiBrandIntro from '../../../components/molecules/SoloMultiBrandIntro';
export type SoloBrandBlockProps = Omit<BlockProps, 'data'> & {
  data: Data;
};
const SoloBrandBlock: FC<SoloBrandBlockProps> = ({
  data,
  popinHandler
}) => {
  const {
    t
  } = useTranslation();
  return data.products && data.products.length > 0 ? <>
      {data.products.map((product, index) => {
      return <SoloBrand id={`Brand-${index}`} key={`Brand-${index}`}>
            {product.__typename === 'SoloBrandProduct' && <SoloBrandIntro title={product.title ?? undefined} subtitle={product.cardTitle ?? undefined} htmlProps={product.cardBody ? {
          text: product.cardBody
        } : undefined} actionButtonProps={product.cardCtaBlock?.href ? {
          label: product.cardCtaBlock.label ?? t('solo_brand_product_button'),
          variant: ActionButtonVariant.secondary,
          href: product.cardCtaBlock?.href,
          iconPosition: 'right',
          iconProps: {
            icon: Icons.arrowRight
          }
        } : undefined}
        // @DEVS : picture with default ratio, uncomment the following if fixed
        // pictureRatio={600 / 375}
        pictureProps={product.image ? {
          alt: product.image.alt ?? ' ',
          maxWidth: 600,
          width: product.image.variations?.[0].width ?? 600,
          height: product.image.variations?.[0].height ?? undefined,
          withHD: true,
          images: wediaImagesTransform(product.image)
        } : undefined} />}
            {product.__typename === 'SoloBrandMultiProduct' && <SoloMultiBrandIntro index={index} title={product.title ?? undefined} items={product.products?.map(product => {
          return {
            subtitle: product.cardTitle ?? undefined,
            htmlProps: product.cardBody ? {
              text: product.cardBody
            } : undefined,
            actionButtonProps: product.cardCtaBlock?.href ? {
              label: product.cardCtaBlock.label ?? t('solo_brand_product_button'),
              variant: ActionButtonVariant.secondary,
              href: product.cardCtaBlock?.href,
              iconPosition: 'right',
              iconProps: {
                icon: Icons.arrowRight
              }
            } : undefined,
            pictureProps: product.image ? {
              alt: product.image.alt ?? ' ',
              maxWidth: 600,
              width: product.image.variations?.[0].width ?? 600,
              height: product.image.variations?.[0].height ?? undefined,
              withHD: true,
              images: wediaImagesTransform(product.image)
            } : undefined,
            reverseOnDesktop: product.bodyPosition === 'RIGHT'
          };
        })} />}
            {product.fullWidthImage && <SoloBrandPicture pictureProps={{
          alt: product.fullWidthImage.alt ?? ' ',
          maxWidth: 1280,
          width: product.fullWidthImage.variations?.[0].width ?? 1280,
          height: product.fullWidthImage.variations?.[0].height ?? undefined,
          withHD: true,
          images: wediaImagesTransform(product.fullWidthImage)
        }} mobilePictureProps={product.fullWidthImageMobile ? {
          alt: product.fullWidthImageMobile.alt ?? ' ',
          maxWidth: 1280,
          width: product.fullWidthImageMobile.variations?.[0].width ?? 1280,
          height: product.fullWidthImageMobile.variations?.[0].height ?? undefined,
          withHD: true,
          images: wediaImagesTransform(product.fullWidthImageMobile)
        } : undefined} />}
            {product.otherFullWidthImage && product.otherFullWidthImage.map((item, index) => <SoloBrandPicture key={`Brand-${index}-fullWidthImage`} pictureProps={{
          alt: item?.image?.alt ?? ' ',
          maxWidth: 1280,
          width: item?.image?.variations?.[0].width ?? 1280,
          height: item?.image?.variations?.[0].height ?? undefined,
          withHD: true,
          images: wediaImagesTransform(item.image)
        }} mobilePictureProps={item?.imageMobile ? {
          alt: item?.imageMobile?.alt ?? ' ',
          maxWidth: 1280,
          width: item?.imageMobile?.variations?.[0].width ?? 1280,
          height: item?.imageMobile?.variations?.[0].height ?? undefined,
          withHD: true,
          images: wediaImagesTransform(item.imageMobile)
        } : undefined} />)}
            {product?.rangeFormats && product.rangeFormats.length > 0 && <SoloBrandFormats relatedProducts={product.rangeFormats.map(format => {
          return {
            title: format.title ?? '',
            htmlTitleTag: 'h3',
            actionButtonsProps: [{
              label: t('product_more_info'),
              href: format?.slug ? Router.getRouteUrl(routes.format, {
                slug: format.slug
              }) : undefined,
              onClick: e => {
                e.preventDefault();
                popinHandler?.(format);
              },
              variant: ActionButtonVariant.secondary
            }],
            imageProps: {
              withHD: true,
              maxWidth: 190,
              width: 190,
              height: 190,
              alt: format?.thumbnail?.alt ?? format?.packshot?.alt ?? format?.title,
              images: wediaImagesTransform(format?.thumbnail ?? format?.packshot)
            }
          };
        })} actionButtonProps={
        // not as a link anymore because of 💩 SEO recommendation
        product.rangeCtaBlock?.href ? {
          label: product.rangeCtaBlock?.label ?? t('learn_more'),
          href: undefined,
          onClick: () => {
            product?.rangeCtaBlock?.href && Router.push(product.rangeCtaBlock.href);
          },
          // rel: 'nofollow',
          iconPosition: 'right',
          iconProps: {
            icon: Icons.arrowRight
          }
        } : undefined} pictureProps={product.rangeImage ? {
          alt: product.rangeImage.alt ?? ' ',
          maxWidth: 1280,
          width: product.rangeImage.variations?.[0].width ?? 1280,
          height: product.rangeImage.variations?.[0].height ?? undefined,
          withHD: true,
          images: wediaImagesTransform(product.rangeImage)
        } : undefined} />}
          </SoloBrand>;
    })}
    </> : null;
};
export default SoloBrandBlock;